<template>
	<main>
		<div class="background"></div>
		<section class="moon">
			<img src="../../assets/images/AVP1.png" class="bgImage" />
			<img src="../../assets/images/AVP1.gif" class="bgImage" />
			<div class="subgrid">
				<div class="moonshot">
					AVP is a community of diverse people doing what they do best - being #PrettyAverage.
					And that includes some pretty average <router-link :to="{ path: '/', hash: '#utility' }" class="cursor-pointer text-purple-600 hover:text-white font-semibold">
						utility
					</router-link> as well, like eating $PZA while getting alpha!
				</div>
			</div>
		</section>
		<section class="roof" id="utility">
			<img src="../../assets/images/AVP2.png" class="bgImage" />
			<div class="content">
				<p v-if="isMobile" class="flex justify-evenly">
					<ul class="mt-2">
					<label class="text-xl font-semibold">AVP Utilities</label>
						<li><b>Earn $PZA</b></li>
						<li><b>$PZA Market</b></li>
						<li><b>Quality Alpha</b></li>
						<li><b>Learning & Development</b></li>
						<li><b>Training courses</b></li>
						<li><b>Web3 Job Placement</b></li>
						<li>AVP holder-only <b>giveaways</b></li>
						<li><b>Project Incubator</b></li>
					</ul>
					<ul class="mt-2">
					<label class="text-xl font-semibold">MVP Utilities</label>
						<li>All AVP Utilities <b>plus:</b></li>
						<li><b>Earn additional $PZA</b></li>
						<li>MVP holder-only <b>giveaways</b></li>
					</ul>
				</p>
				<p v-else class="mt-2">
					<label class="text-xl font-semibold">AVP Utilities</label>
					<ul class="mt-2 mb-4">
						<li><b>Earn $PZA</b> just from holding AVP and MVPs - no staking needed!!</li>
						<li><b>$PZA Market</b> Spend your $PZA on WL spots, NFT's, IRL Merch, Surprise PZA Boxes, and more</li>
						<li><b>Quality Alpha</b> and analysis by trained NFT analysts and NFT Whales holding several bluechips</li>
						<li><b>Learning & Development</b> of the NFT Space with Whales and NFT Specialists</li>
						<li><b>Training courses</b> Discord Community Management, Head Mod, Mod, and Discord security</li>
						<li><b>Web3 Job Placement</b> We help find Web3 jobs for our graduates and holders</li>
						<li>AVP holder-only <b>giveaways</b></li>
						<li><b>Project Incubator</b> to provide services for artists who want to launch their own brand</li>
					</ul>
					<label class="text-xl font-semibold">MVP Utilities</label>
					<ul class="mt-2">
						<li>All AVP Utilities <b>plus:</b></li>
						<li><b>Earn additional $PZA</b> from holding an MVP</li>
						<li>MVP holder-only <b>giveaways</b></li>
					</ul>
				</p>
			</div>
		</section>
		<section class="floor">
			<img src="../../assets/images/AVP3.png" class="bgImage" />
			<img src="../../assets/images/AVP3.gif" class="bgImage" />
			<router-link :to="{ path: '/pza-market' }" class="cursor-pointer enter">
			</router-link>
		</section>
		<section class="underground">
			<img src="../../assets/images/AVP4.png" class="bgImage" />
			<div class="content">
				<div class="avps flex flex-row flex-wrap" :class="[isMobile ? 'mobile' : '']">
					<img src="../../assets/images/avps/2728.png" />
					<img src="../../assets/images/avps/680.png" />
					<img src="../../assets/images/avps/1700.png" />
					<img src="../../assets/images/avps/2537.png" />
					<img src="../../assets/images/avps/mascot-604.png" />
					<img src="../../assets/images/avps/1817.png" />
					<img src="../../assets/images/avps/maskwa-3507.png" />
					<img src="../../assets/images/avps/2638.png" />
					<img src="../../assets/images/avps/3396.png" />
				</div>
				<p>
					<b>Average Punks (AVPs)</b> are 4200 randomly generated 3D Characters punking around the Ethereum blockchain as ERC-721
					tokens and hosted on IPFS. Full characters will be able to be uploaded to DCL soon.
					See you all on the Metaverse!
					<a href="https://opensea.io/collection/averagepunks-" class="cursor-pointer text-purple-600 hover:text-white font-semibold">Get your Average Punk here</a>
					<br/><br/>
					<b>MetaVerse Punks (MVPs)</b> are unique 1 of 1 characters designed to roam the Metaverse with their distinctive traits. MVPs are the Genesis On-Chain collection within the Average Punk Universe.

					<a href="https://opensea.io/collection/metaverse-punks-" class="cursor-pointer text-purple-600 hover:text-white font-semibold">Get your Metaverse Punk here</a>
				</p>
			</div>
		</section>
		<section class="bar" id="team">
			<img src="../../assets/images/AVP5.png" class="bgImage" />
			<img src="../../assets/images/AVP5.gif" class="bgImage" />
			<div v-if="isMobile" class="partners mobile">
				<div v-for="selectedPartner in partners" :key="selectedPartner.id">
					<div class="partner show">
						<div class="flex flex-col pfp gap-2">
							<label class="text-purple-700">{{selectedPartner.name}}</label>
							<img :src='selectedPartner.profile' alt="Member PFP"/>
							<div class="socials justify-evenly items-center flex flex-row justify-self-end">
								<twitter-icon v-if="selectedPartner.twitter" :link="selectedPartner.twitter"/>
								<linkedIn-icon v-if="selectedPartner.linkedIn" :link="selectedPartner.linkedIn"/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-else class="partners">
				<div v-for="selectedPartner in partners" :key="selectedPartner.id"
					@click="partnerClick(selectedPartner.id)"
					@mouseover="partnerClick(selectedPartner.id)"
					@mouseout="selectedPartnerId = 0">
					<div class="partner" :class="selectedPartnerId === selectedPartner.id ? 'show': ''">
						<div class="flex flex-col pfp gap-2">
							<label class="text-purple-700">{{selectedPartner.name}}</label>
							<img :src='selectedPartner.profile' alt="Member PFP"/>
							{{selectedPartner.summary}}
							<div class="socials justify-evenly items-center flex flex-row justify-self-end">
								<twitter-icon v-if="selectedPartner.twitter" :link="selectedPartner.twitter"/>
								<linkedIn-icon v-if="selectedPartner.linkedIn" :link="selectedPartner.linkedIn"/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</main>
</template>

<script>
import TwitterIcon from '../../components/TwitterIcon.vue';
import LinkedInIcon from '../../components/LinkedInIcon.vue';

export default {
	name: 'Home',
	data: () => ({
		isMobile: true,
		showModal: false,
		selectedPartnerId: '',
		partners: {
			'maskwa':{
				id: 'maskwa',
				profile:require('../../assets/images/maskwa_pfp.png'),
				name: 'Brad Provencher',
				summary: 'Ran companies, built non-profits, and know how to streamline for success.',
				twitter: 'https://twitter.com/incendiarylyfe',
				linkedIn: 'https://www.linkedin.com/in/bradprovencher/',
			},
			'furp':{
				id: 'furp',
				profile:require('../../assets/images/furp_pfp.png'),
				name: 'Frank Uriostegui',
				summary: 'Striving to create a strong foundation for a solid future to take care of those closest to him.',
				twitter: 'https://twitter.com/furpmr',
				linkedIn: '',
			},
			'alpha':{
				id: 'alpha',
				profile:require('../../assets/images/alpha_pfp.png'),
				name: 'Paul Bhogal',
				summary: 'Firm believer in breaking barriers and taking chances.',
				twitter: 'https://twitter.com/alphageek79',
				linkedIn: 'https://www.linkedin.com/in/paul-bhogal-3490264/',
			},
			'mngo':{
				id: 'mngo',
				profile:require('../../assets/images/mngo_pfp.png'),
				name: 'Jon Van Wyk',
				summary: 'Experience with managing people, leading programs, and creating strategic plans.',
				twitter: 'https://twitter.com/jonvwyk',
				linkedIn: '',
			},
			'super':{
				id: 'super',
				profile:require('../../assets/images/supermanciero_pfp.png'),
				name: 'Chris Manciero',
				summary: 'Works hard to get the job done, but also has fun doing it.',
				twitter: 'https://twitter.com/chrismanciero',
				linkedIn: 'https://www.linkedin.com/in/chris-manciero-095a653/',
			},
		},
		selectedPartner: {}
	}),
	components: {
		TwitterIcon,
		LinkedInIcon,
	},
	created()  {
		this.addEventListener();
		this.onResize();
	},
	destroyed(){
		this.removeEventListener();
	},
	methods: {
		onResize: function() {
			this.isMobile = (window.innerHeight <= 500 || window.innerWidth <= 1000);
		},
		addEventListener: function() {
			window.addEventListener('resize', this.onResize, {passive: true});
		},
		removeEventListener: function() {
			window.removeEventListener('resize', this.onResize, {passive: true});
		},
		partnerClick: function(member){
			this.showModal = true;
			this.selectedPartnerId = member;
			this.selectedPartner = this.partners[member];
		}
	}
};
</script>
